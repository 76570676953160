// pendoSetup.js

/**
 * Initialize Pendo using the provided snippet and API Key.
 * This function sets up Pendo by injecting the Pendo script into the document.
 *
 * @param {string} apiKey - Your Pendo API Key.
 */
export const initializePendo = (apiKey) => {
  // Ensure the Pendo snippet is only added once
  if (!window.pendo) {
    console.log('Attempting to load Pendo script...');
    (function(apiKey) {
      (function(p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w) (function(m) {
          o[m] = o[m] || function() {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
        })(v[w]);
        y = e.createElement(n);
        y.async = true;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        y.onload = () => console.log('Pendo script loaded successfully.');
        y.onerror = (err) => console.error('Pendo script failed to load:', err);
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })(apiKey);

    // Check if Pendo was added to the window object
    setTimeout(() => {
      if (window.pendo) {
        console.log('Pendo object is defined:', window.pendo);
        window.pendo.initialize({
          visitor: {
            id: Math.random().toString(36).substring(7),  // Generate a random session-based visitor ID
          },
          account: {
            id: 'public',  // Use a generic account ID if needed
          },
        });
      } else {
        console.error('Pendo object is not defined after script load.');
      }
    }, 3000);  // Adjust timeout as needed
  }
};

// Initialize Pendo with your specific API key
initializePendo('ef151c20-2498-452d-58eb-2e1463c5ddab');  // Replace with your actual Pendo API key
