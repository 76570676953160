/* global adsbygoogle */
import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import ErrorBoundary from './ErrorBoundary'; // Error handling for the entire app
import AI from './components/AI';
import Communication from './components/Communication';
import Worship from './components/Worship';
import Administration from './components/Administration';
import Outreach from './components/Outreach';
import Education from './components/Education';
import Content from './components/Content';
import Web from './components/Web'; // Web component

function App() {
  const adRef = useRef(null); // Reference to track the ad element
  const [adLoaded, setAdLoaded] = useState(false); // State to track if the ad is loaded. Default is false (hidden)

  const currentYear = new Date().getFullYear(); // Get the current year for the footer

  /**
   * This effect handles the loading of Google AdSense ads.
   * By default, the ad container starts hidden. It will only be displayed if
   * the AdSense script confirms that an ad has successfully loaded.
   */
  useEffect(() => {
    const loadAd = () => {
      // Only try to load the ad if AdSense is available and the ad hasn't already been loaded
      if (window.adsbygoogle && adRef.current && !adRef.current.getAttribute('data-ad-loaded')) {
        try {
          // Initialize AdSense and mark the ad slot as loaded
          (adsbygoogle = window.adsbygoogle || []).push({});
          adRef.current.setAttribute('data-ad-loaded', 'true');
          setAdLoaded(true); // Ad is successfully loaded, display the ad container
        } catch (error) {
          console.error("AdSense initialization error:", error);
          setAdLoaded(false); // Ad failed to load, hide the ad container
        }
      } else {
        setAdLoaded(false); // No AdSense ads available, so hide the ad container
      }
    };

    // Wrap ad loading in a try-catch to handle any unforeseen script issues gracefully
    try {
      loadAd();
    } catch (error) {
      console.error("Error in loading AdSense script:", error);
      setAdLoaded(false); // Ensure the ad container is hidden if the script fails
    }
  }, []); // Empty dependency array to run only once on mount

  return (
    <ErrorBoundary> {/* Wrap the entire application inside the ErrorBoundary */}
      <div className="App">
        <header className="App-header">
          <h1>Church Technology Landscape</h1>

          {/* Google AdSense ad slot */}
          <ins
            ref={adRef}
            className="adsbygoogle google-ad-slot"
            style={{ display: adLoaded ? 'block' : 'none' }} // Dynamically hide the ad slot if the ad is not loaded
            data-ad-client="ca-pub-5848898043759530"  // Replace with actual AdSense publisher ID
            data-ad-slot="7667607227"                 // New ad unit created 2024-10-27
            // data-ad-test="on"                         // TURN THIS OFF FOR PRODUCTION DEPLOYS!!!
            data-ad-format="auto"
            data-full-width-responsive="true"
            crossOrigin="anonymous"  // Optional: Adds cross-origin attribute to handle potential security restrictions.  Camelcase to stop warning message
          ></ins>
          <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        </header>

        {/* Components for different categories */}
        <AI />
        <Communication />
        <Worship />
        <Administration />
        <Outreach />
        <Education />
        <Content />
        <Web />

        <footer className="App-footer">
          &copy; {currentYear} <a href="https://www.digitalministrylabs.io/" target="_blank" rel="noopener noreferrer">Digital Ministry Labs</a>
          <a href="https://digitalministrylabs.atlassian.net/servicedesk/customer/portal/4" target="_blank" rel="noopener noreferrer">Feedback</a>
          <a href="https://logo.dev" alt="Logo API" target="_blank" rel="noopener noreferrer">Logos provided by Logo.dev</a>
        </footer>
      </div>
    </ErrorBoundary>
  );
}

export default App;
