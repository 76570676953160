import React, { useEffect, useState } from 'react';
import './Worship.css';

function Worship() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('/data/Worship.json')
      .then(response => response.json())
      .then(data => setData(data.Worship)) // Access the "Worship" key directly
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  if (!data) return <div>Loading...</div>;

  return (
    <div className="category">
      <h2>Worship</h2>
      <div className="subcategories">
        {Object.keys(data).map(subcategory => (
          <div className="subcategory" key={subcategory}>
            <h3>{subcategory}</h3>
            <div className="items">
              {Array.isArray(data[subcategory]) ? (
                data[subcategory].map(item => (
                  <div className="item" key={item.name}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <img src={item.logo} alt={`${item.name} logo`} />
                      <span className="item-name">{item.name}</span>
                    </a>
                  </div>
                ))
              ) : (
                <p>Invalid data format for {subcategory}</p> // In case data is not an array
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Worship;
