import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false }; // Track if there’s an error
  }

  // Update state if there’s an error
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // Log the error for debugging
  componentDidCatch(error, info) {
    console.error("ErrorBoundary caught an error", error, info);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI when an error is caught
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; // Render the child components when no error is caught
  }
}

export default ErrorBoundary;
